<template>
  <div v-if="live && live.id" class="templates-live-bar">
    <div class="container d-flex align-items-center">
      <img :src="live.cover" />
      <strong class="mx-4 pe-4 border-end">
        ESTAMOS<br />
        AO VIVO
      </strong>
      <div class="flex-fill d-flex align-items-center pe-4">
        <small class="me-2">Módulo {{ live.module.sort }}</small>
        <strong>{{ live.name }}</strong>
      </div>
      <router-link
        class="btn btn-dark d-inline-flex align-items-center"
        :to="{ name: 'lesson', params: { id: live.id, slug: live.slug } }"
      >
        <i class="icon-play lead me-2"></i>
        <strong>Assistir Agora</strong>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("courses", ["live"]),
  },
  mounted() {
    this.$store.dispatch("courses/getLive");
  },
};
</script>

<style lang="scss" scoped>
.templates-live-bar {
  background-color: #a7b719;
  color: white;
  padding: 16px 0;

  img {
    object-fit: cover;
    max-width: 100px;
    max-height: 60px;
    min-width: 100px;
    min-height: 60px;
  }

  strong {
    border-color: white;
    font-size: 18px;
  }
}
</style>
