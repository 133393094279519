<template>
  <span class="loading">
    <span v-if="!show">
      <slot />
    </span>
    <span v-else>&nbsp;</span>

    <div v-if="show" class="spinner-border"></div>
  </span>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.loading {
  .spinner-border {
    width: 1rem;
    height: 1rem;
  }
}
</style>
