<template>
  <footer class="site-footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <img src="@/assets/images/logo.png" class="logo" />
        </div>
        <div class="col-lg-3">
          <div class="py-2">
            <router-link :to="{ name: 'index' }" class="text-light">
              Início
            </router-link>
          </div>
          <div class="py-2">
            <a href="#" class="text-light"> Minha Conta </a>
          </div>
          <div class="py-2">
            <a href="#" class="text-light"> Sair </a>
          </div>
          <div class="py-2">
            <a href="#" class="text-light"> Ajuda </a>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="py-2">
            <router-link :to="{ name: 'terms' }" class="text-light">
              Termos de Uso
            </router-link>
          </div>
          <div class="py-2">
            <router-link :to="{ name: 'privacy-policy' }" class="text-light">
              Privacidade
            </router-link>
          </div>
          <div class="py-2">
            <a href="#" class="text-light"> Contato </a>
          </div>
        </div>
        <div class="col-lg-3"></div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.site-footer {
  padding: 60px 0;
  background: #232020;

  .logo {
    max-width: 100%;
    width: 100px;
  }

  a.text-light {
    text-decoration: none;
  }
}
</style>
