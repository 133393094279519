<template>
  <div v-if="logged" class="template-default">
    <live-bar />

    <top-bar @signout="confirmSignout = true" />

    <action-dispatcher action="courses/getAll">
      <slot />
    </action-dispatcher>

    <site-footer class="mt-5" />
  </div>

  <confirmation
    v-model="confirmSignout"
    title="Sair"
    text="Deseja mesmo sair?"
    @confirm="signout"
  />
</template>

<script>
import SiteFooter from "@/components/templates/SiteFooter";
import TopBar from "@/components/templates/TopBar";
import LiveBar from "@/components/templates/LiveBar";
import { mapState } from "vuex";

export default {
  components: {
    SiteFooter,
    TopBar,
    LiveBar,
  },
  data() {
    return {
      confirmSignout: false,
    };
  },
  computed: {
    ...mapState("accounts", ["user", "logged"]),
  },
  methods: {
    signout() {
      this.$store.dispatch("accounts/signout");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.template-default {
  main {
    min-height: 100vh;
  }
}
</style>
