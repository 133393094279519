<template>
  <div class="button-nav">
    <button
      v-for="option in options"
      :key="option.id"
      class="btn"
      :class="{ active: modelValue === option.id }"
      @click="$emit('update:modelValue', option.id)"
    >
      {{ option.name }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    modelValue: [Number, String],
  },
};
</script>

<style lang="scss" scoped>
.button-nav {
  margin: 0 -10px;
  display: flex;

  button {
    margin: 0 10px;
    border: 1px solid white;
    color: #73778c;
    font-weight: bold;
    border-radius: 10px;

    &:hover,
    &.active {
      background: white;
      color: #434fcc;
    }
  }
}
</style>
