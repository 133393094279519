import { get } from "lodash";

export default {
  getAll(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/courses")
        .then((response) => {
          context.commit("setCourses", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },
  getLessons(context, module) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/courses/lessons/${module}`)
        .then((response) => {
          context.commit("setLessons", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },
  getLesson(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/courses/lessons/${id}/get`)
        .then((response) => {
          context.commit("setLesson", response.data);
          resolve();
        })
        .catch(reject);
    });
  },
  getSupportMaterials(context) {
    const courseId = get(context.getters.currentCourse, "id");
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/courses/support-material/${courseId}`)
        .then((response) => {
          context.commit("setSupportMaterials", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },
  getLive(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/courses/live")
        .then((response) => {
          context.commit("setLive", response.data);
          resolve();
        })
        .catch(reject);
    });
  },
};
