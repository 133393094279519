export default {
  reset(context) {
    context.commit("setComments", []);
    context.commit("setCount", 0);
  },
  get(context, params) {
    const queryString = new URLSearchParams(params).toString();
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/comments?${queryString}`)
        .then((response) => {
          context.commit("addComments", response.data.results);
          context.commit("setCount", response.data.count);
          resolve();
        })
        .catch(reject);
    });
  },
  add(context, data) {
    return new Promise((resolve, reject) => {
      window.axios.post("/comments", data).then(resolve).catch(reject);
    });
  },
  update(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/comments/${data.id}`, data)
        .then(resolve)
        .catch(reject);
    });
  },
  remove(context, id) {
    return new Promise((resolve, reject) => {
      window.axios.delete(`/comments/${id}`).then(resolve).catch(reject);
    });
  },
};
