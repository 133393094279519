<template>
  <el-dialog
    :model-value="modelValue"
    @closed="closed"
    custom-class="confirmation"
    title="Confirmação"
  >
    <div class="text-center">
      <h4>{{ title }}</h4>
      <div v-html="text" />
    </div>

    <template #footer>
      <div class="d-flex justify-content-between mx-n3">
        <button
          type="button"
          class="btn btn-outline-secondary mx-3"
          @click="cancel"
        >
          Cancelar
        </button>
        <button type="button" class="btn btn-primary mx-3" @click="confirm">
          <loading :show="loading"> Confirmar </loading>
        </button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
    title: String,
    text: String,
    loading: Boolean,
  },
  emits: ["confirm", "cancel"],
  methods: {
    cancel() {
      this.$emit("cancel");
      this.$emit("update:modelValue", false);
    },
    confirm() {
      this.$emit("confirm");
    },
    closed() {
      this.$emit("update:modelValue", false);
    },
  },
};
</script>

<style lang="scss">
.confirmation {
  width: 80% !important;
  max-width: 400px !important;
}
</style>
