import { get } from "lodash";
import { getToken } from "@/api";
import actions from "./actions";

export default {
  namespaced: true,

  state: () => ({
    logged: !!getToken(),
    user: null,
    gallery: [],
    count: 0,
  }),

  getters: {
    isAdmin(state) {
      return get(state.user, "type") === "admin";
    },
  },

  mutations: {
    setLogged(state, logged) {
      state.logged = logged;
    },
    setUser(state, user) {
      state.user = user;
    },
    setGallery(state, gallery) {
      state.gallery = gallery;
    },
    setFavorites(state, favorites) {
      state.favorites = favorites;
    },
    setCount(state, count) {
      state.count = count;
    },
  },

  actions,
};
