<template>
  <div v-if="loading" class="text-center p-5">
    <div class="spinner-border text-primary"></div>
  </div>

  <component v-else-if="$route.meta.template" :is="$route.meta.template">
    <router-view :key="$route.fullPath" />
  </component>

  <router-view v-else />
</template>

<script>
import { getToken } from "@/api";
import { mapState } from "vuex";

let firstLoad = true;

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("accounts", ["user", "logged"]),
  },
  watch: {
    "$route.name": function () {
      if (!this.logged && !this.$route.meta.open) {
        window.location = `${window.location.origin}/login?path=${window.location.pathname}`;
      }
      if (!firstLoad) {
        window.scrollTo(0, 0);
      }
      firstLoad = false;
    },
  },
  mounted() {
    if (getToken()) {
      this.loading = true;

      this.$store.dispatch("accounts/get").finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>
