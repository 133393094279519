<template>
  <div class="templates-top-bar">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-8">
          <img src="@/assets/images/logo.png" class="logo me-5" />
          <router-link :to="{ name: 'index' }" class="text-success"
            >Início</router-link
          >
        </div>
        <div class="col-4 d-flex align-items-center justify-content-end">
          <el-dropdown v-if="user" trigger="click">
            <span class="el-dropdown-link">
              Olá, {{ user.name }}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <div class="text-center px-4 py-3">
                  <h6 class="mb-0">{{ user.name }}</h6>
                  <small class="text-secondary">{{ user.email }}</small>
                </div>
                <el-dropdown-item @click="$router.push({ name: 'account' })"
                  >Minha conta</el-dropdown-item
                >
                <el-dropdown-item @click="$emit('signout')">
                  Sair
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      profile: false,
    };
  },
  computed: {
    ...mapState("accounts", ["user"]),
  },
};
</script>

<style lang="scss" scoped>
.templates-top-bar {
  padding: 30px 0;
  background-color: #131212;
  border-bottom: 3px solid black;

  .logo {
    height: 50px;
    width: auto;
  }

  input {
    min-height: 44px;
    border: none;
    border-radius: 44px;
  }

  a {
    font-weight: bold;
    text-decoration: none;
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #73778c;
    margin: 0 12px;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }

    i {
      font-size: 28px;
    }
  }

  .profile-avatar {
    margin-right: 0;
  }
}
</style>
