import { get } from "lodash";

export default {
  getAll(context) {
    const courseId = get(context.rootGetters["courses/currentCourse"], "id");
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/podcasts/${courseId}`)
        .then((response) => {
          context.commit("setPodcasts", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },
};
