import ActionDispatcher from "./ActionDispatcher.vue";
import Confirmation from "./Confirmation.vue";
import FormGroup from "./FormGroup.vue";
import ImageInput from "./ImageInput.vue";
import Loading from "./Loading.vue";
import Pagination from "./Pagination.vue";
import ButtonNav from "./ButtonNav.vue";
import UserPhoto from "./UserPhoto.vue";
import FileInput from "./FileInput.vue";
import StickyNav from "./StickyNav.vue";

export const registerComponents = (app) => {
  app.component("action-dispatcher", ActionDispatcher);
  app.component("confirmation", Confirmation);
  app.component("form-group", FormGroup);
  app.component("image-input", ImageInput);
  app.component("loading", Loading);
  app.component("pagination", Pagination);
  app.component("button-nav", ButtonNav);
  app.component("user-photo", UserPhoto);
  app.component("file-input", FileInput);
  app.component("sticky-nav", StickyNav);
};
