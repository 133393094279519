import { createStore } from "vuex";
import accounts from "./accounts";
import courses from "./courses";
import podcasts from "./podcasts";
import comments from "./comments";

export default createStore({
  modules: {
    accounts,
    courses,
    podcasts,
    comments,
  },
});
