import actions from "./actions";
import { get } from "lodash";

export default {
  namespaced: true,

  state: () => ({
    courses: [],
    lessons: [],
    lesson: {},
    supportMaterials: [],
    live: {},
  }),

  getters: {
    currentCourse(state) {
      return get(state.courses, "[0]");
    },
  },

  mutations: {
    setCourses(state, courses) {
      state.courses = courses;
    },
    setLessons(state, lessons) {
      state.lessons = lessons;
    },
    setLesson(state, lesson) {
      state.lesson = lesson;
    },
    setSupportMaterials(state, supportMaterials) {
      state.supportMaterials = supportMaterials;
    },
    setLive(state, live) {
      state.live = live;
    },
  },

  actions,
};
