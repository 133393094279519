import { createRouter, createWebHistory } from "vue-router";
import Default from "../templates/Default.vue";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/index.vue"),
    meta: {
      template: Default,
    },
  },
  {
    path: "/minha-conta",
    name: "account",
    component: () => import("../views/account.vue"),
    meta: {
      template: Default,
    },
  },
  {
    path: "/:id/:slug",
    name: "lesson",
    component: () => import("../views/lesson.vue"),
    meta: {
      template: Default,
    },
  },
  // Autenticação
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
    meta: {
      open: true,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/reset-password.vue"),
    meta: {
      open: true,
    },
  },
  {
    path: "/politica-de-privacidade",
    name: "privacy-policy",
    component: () => import("../views/privacy.vue"),
    meta: {
      open: true,
    },
  },
  {
    path: "/termos-de-uso",
    name: "terms",
    component: () => import("../views/terms.vue"),
    meta: {
      open: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
