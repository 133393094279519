import actions from "./actions";

export default {
  namespaced: true,
  state: {
    comments: [],
    count: 0,
  },
  mutations: {
    setComments(state, comments) {
      state.comments = comments;
    },
    addComments(state, comments) {
      state.comments = [...state.comments, ...comments];
    },
    setCount(state, count) {
      state.count = count;
    },
  },
  actions,
};
