<template>
  <div id="sticky-nav">
    <button
      v-for="option in options"
      :key="option.id"
      @click="scrollTo(option)"
      :class="{ current: current === option.id }"
    >
      {{ option.name }}
    </button>
  </div>
</template>

<script>
import { last } from "lodash";

export default {
  props: {
    options: Array,
  },
  data() {
    return {
      current: this.options[0].id,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    scrollTo(option) {
      document.getElementById(option.id).scrollIntoView();
    },
    onScroll() {
      if (
        window.innerHeight + document.documentElement.scrollTop + 10 >
        document.body.offsetHeight
      ) {
        // is page bottom
        this.current = last(this.options).id;
        return;
      }

      for (let i = this.options.length - 1; i >= 0; i--) {
        if (
          document.documentElement.scrollTop >=
          document.getElementById(this.options[i].id).offsetTop - 10
        ) {
          this.current = this.options[i].id;
          break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#sticky-nav {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  background: #0b0a0a99;
  backdrop-filter: blur(8px);
  z-index: 99;

  button {
    border: none;
    background: none;
    padding: 16px 40px;
    border-bottom: 3px solid #2d2a2a;
    color: white;

    &.current {
      border-color: #009bc5;
    }
  }
}
</style>
