import actions from "./actions";

export default {
  namespaced: true,

  state: () => ({
    podcasts: [],
  }),

  mutations: {
    setPodcasts(state, podcasts) {
      state.podcasts = podcasts;
    },
  },

  actions,
};
