import { setToken, removeToken } from "@/api";

export default {
  signout(context) {
    context.commit("setLogged", false);
    context.commit("setUser", null);
    removeToken();
  },

  signin(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/accounts/signin", data)
        .then((response) => {
          setToken(response.data.access);
          context.commit("setLogged", true);
          resolve(response.data);
        })
        .catch(reject);
    });
  },

  get(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/accounts")
        .then((response) => {
          context.commit("setUser", response.data);
          resolve();
        })
        .catch(reject);
    });
  },

  put(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put("/accounts", data)
        .then((response) => {
          context.commit("setUser", response.data);
          resolve();
        })
        .catch(reject);
    });
  },

  changePassword(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put("/accounts/change-password", data)
        .then(resolve)
        .catch(reject);
    });
  },

  forgot(context, data) {
    return new Promise((resolve, reject) => {
      window.axios.post("/accounts/forgot", data).then(resolve).catch(reject);
    });
  },

  resetPassword(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/accounts/reset-password", data)
        .then(resolve)
        .catch(reject);
    });
  },
};
